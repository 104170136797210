html,
body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  background-color: #f5f5dc;
  // background-color: #fedcd2;
}
::selection {
  background: cyan;
  color: red;
}
*,
*::after,
*::before {
  box-sizing: border-box;
}

@font-face {
  font-family: "Typewriter";
  src: url("./assets/fonts/typewcond_regular.otf");
}

@font-face {
  font-family: "RobotoMono";
  src: url("./assets/fonts/Roboto_Mono/RobotoMono-Regular.ttf");
  font-weight: normal;
}

@font-face {
  font-family: "RobotoLight";
  src: url("./assets/fonts/Roboto_Mono/RobotoMono-Light.ttf");
  font-weight: normal;
}

@font-face {
  font-family: "RobotoBold";
  src: url("./assets/fonts/Roboto_Mono/RobotoMono-Bold.ttf");
  font-weight: normal;
}

@font-face {
  font-family: "RobotoThin";
  src: url("./assets/fonts/Roboto_Mono/RobotoMono-Thin.ttf");
  font-weight: normal;
}

@font-face {
  font-family: "RobotoMedium";
  src: url("./assets/fonts/Roboto_Mono/RobotoMono-Medium.ttf");
  font-weight: normal;
}

@font-face {
  font-family: "Rokkitt";
  src: url("./assets/fonts/Rokkitt.ttf");
  font-weight: normal;
}

@font-face {
  font-family: "Rokkitt-Medium";
  src: url("./assets/fonts/Rokkitt-Medium.ttf");
  font-weight: normal;
}

.App {
  text-align: center;
}

.App-header {
  // background-color: black;
  min-height: 100vh;
  display: flex;
  /* flex-direction: column;
  align-items: center;
  justify-content: center; */
  font-size: calc(10px + 2vmin);
  color: white;
}

.emptyBox {
  background-size: cover;
}

.Title-text {
  display: inline-block;
  position: absolute;
  color: black;
  margin-top: 1%;
  left: 4%;
  width: auto;
  font-family: Courier;
  font-style: normal;
  overflow: hidden;
  padding: 2px;
  line-height: 50px;
  font-size: 32px;
  letter-spacing: 0.1em;
  z-index: 999999;
  transition: background-color 300ms, color 300ms;
  cursor: pointer;
  @media only screen and (max-width: 500px) {
    font-size: 18px;
    line-height: 30px;
  }
}
.Title-text:hover {
  color: #f5f5dc;
  background-color: black;
}

.Title-text-landing {
  display: inline-block;
  position: relative;
  background-color: rgba(0, 0, 0, 0.9);
  color: white;

  margin-top: 33vh;
  margin-left: 50%;
  transform: translate(-50%, 0%);
  text-align: center;
  font-family: Courier;
  font-style: normal;
  overflow: hidden;
  padding: 4px;
  text-transform: uppercase;
  font-size: 42px;
  letter-spacing: 0.07em;
  z-index: 999999;
  @media only screen and (max-width: 500px) {
    font-size: 20px;
    line-height: 30px;
  }
}

#openingText {
  opacity: 1;
  transition: opacity 350ms ease-in-out;
}

#landingMasonry {
  opacity: 1;
  transition: opacity 500ms ease-in-out;
}

.fade-exit {
  opacity: 0 !important;
}

.my-masonry-grid {
  display: flex;
  position: fixed;
  z-index: 0;
  margin-left: 0.5%;
  margin-top: 0.3%;
  -webkit-user-select: none; /* Chrome/Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+ */
}
.my-masonry-grid_column {
  // border-left: 5px solid transparent;
  // border: none;
  background-clip: padding-box;
  margin-left: 0px;
  // padding-left: 10px;
}
.my-masonry-grid:last-child {
  flex-grow: 1;
}

/* Style your items */
.my-masonry-grid_column > div {
  /* change div to reference your elements in <masonry> */
  // background: #ddd;
  padding-top: 2px;
  // margin-left: 4px;
}

.ReactModalPortal > * {
  // When the modal is closed, overlay div has no css class
  // This selector should be overridden by the `&--after-open` class below
  opacity: 0;
}

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 500ms ease-in-out;

  &--after-open {
    opacity: 1;
    z-index: 9999999;
  }

  &--before-close {
    opacity: 0;
  }
}

.ReactModal__Body--open,
.ReactModal__Html--open {
  overflow: hidden;
}

#maskTextDiv {
  position: -webkit-sticky;
  display: table;
  position: absolute;
  top: 50;
  left: 50;
  justify-content: center;
  align-items: center;
  text-align: left;
  font-family: Typewriter;
  font-size: 28px;
  color: black;
  background-color: yellow;
  z-index: 99999999999;
  padding-left: 0.5%;

  @media only screen and (max-width: 500px) {
    font-size: 18px;
  }
}

.maskImgs {
  transition: box-shadow 300ms ease-in-out;
  -webkit-filter: blur(7px);
  filter: blur(7px);
  opacity: 0.5;
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}

.maskImgs:hover {
  -webkit-filter: blur(0);
  filter: blur(0);
  opacity: 1;

  z-index: 10000;
}

.button-fade-in {
  opacity: 1 !important;
}

#keyphraseStyle {
  position: relative;
  font-family: "RobotoMono";
  font-size: 50px;
  margin-top: 5%;
  left: 4%;
  word-spacing: 4px;
  line-height: 72px;
  text-transform: lowercase;
  z-index: 9999999;
  width: 90%;
  opacity: 0;
  transition: opacity 1.5s;

  @media only screen and (max-width: 500px) {
    font-size: 30px;
    line-height: 50px;
  }

  @media all and (min-width: 1824px) {
    font-size: 60px;
    line-height: 88px;
  }
}

.keyphraseP {
  margin-bottom: 10%;
}

.keyphraseP span {
  color: black;
  padding: 6px;
  background-color: #e2a87a;
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
  transition: color 200ms linear, background-color 200ms linear;
  cursor: help;
}

.keyphraseP span:hover {
  background-color: black;
  // color: #7ae2a8;
  color: #7a49a5;
}

.inActive {
  visibility: hidden !important;
}
